import {
  IOption,
  SelectDropdown,
  TextArea,
  TextField,
  TimePickerInput,
} from "@patientmpower/spiro";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Form, Formik, FormikProps } from "formik";
import { forwardRef, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { IMeasurementTypeOptions } from "../../../../../../@types/Measurements";
import {
  IWorkflowsSelectOption,
  IWorkflowsSurveyOption,
  IWorkflowResponse,
} from "../../../../../../@types/Worflows";
import { ArrowDown } from "../../../../../../assets/icons/ArrowDown";
import {
  ErrorMessage,
  selectCss,
  textAreaCss,
} from "../../WorkflowsBuilder.styles";
import {
  BottomContainer,
  Container,
  OptionsContainer,
  popupOptionsCss,
  SelectContainer,
  TextFieldCss,
  Title,
  TrueOrFalseBox,
} from "./ResponseComponent.styles";

interface IResponseComponentBaseProps {
  responseTypes?: IWorkflowsSelectOption[];
  responseTimes?: IWorkflowsSelectOption[];
  actionTypes?: IWorkflowsSelectOption[];
  surveyOptions?: IWorkflowsSurveyOption[];
  measurementTypes?: IMeasurementTypeOptions[];
}

interface IResponseComponentFormikProps extends IResponseComponentBaseProps {
  content?: IWorkflowResponse;
}

interface IResponseComponentProps extends IResponseComponentBaseProps {
  formik: FormikProps<IWorkflowResponse>;
}

export function ResponseComponent({
  formik,
  responseTypes,
  responseTimes,
  actionTypes,
  surveyOptions,
  measurementTypes,
}: IResponseComponentProps) {
  const [filteredSurveyOptions, setFilteredSurveyOptions] = useState<
    IWorkflowsSurveyOption[]
  >([]);

  const shouldShowErrorMessage = (
    field:
      | "responseType"
      | "actionType"
      | "measurementType"
      | "surveyId"
      | "responseTime"
      | "timeUtc"
      | "messageText"
      | "questionText"
      | "option1Text"
      | "option2Text"
  ) => {
    return formik?.touched[field] ? formik?.errors[field] ?? "" : "";
  };

  const handleMeasurementTypesOptions = () => {
    if (measurementTypes?.length === 0 || !measurementTypes) return [];

    const categoryOrder = ["spirometry", "measurement", "lab_result"];

    const groupedData = measurementTypes?.reduce(
      (accumulator: any, measurementType: IMeasurementTypeOptions) => {
        if (!accumulator[measurementType.category]) {
          accumulator[measurementType.category] = {
            label: measurementType.categoryDisplayName,
            title: measurementType.category,
            options: [],
          };
        }
        accumulator[measurementType.category]?.options?.push({
          label: measurementType.measurementTypeDisplayName,
          value: measurementType.measurementType,
        });
        return accumulator;
      },
      {}
    );

    const orderedData = categoryOrder?.map((category) => {
      if (groupedData[category]) {
        groupedData[category]?.options?.sort((a: IOption, b: IOption) =>
          a.label.localeCompare(b.label)
        );
      }

      return groupedData[category];
    }) as DefaultOptionType[];

    return Object.values(orderedData) ?? [];
  };

  useEffect(() => {
    if (!surveyOptions?.length) return;

    const filteredOptions: IWorkflowsSurveyOption[] = [];

    surveyOptions?.forEach((surveyOption) => {
      const optionIndex = filteredOptions.findIndex(
        (option) => option.surveyId === surveyOption.surveyId
      );
      if (optionIndex === -1) {
        filteredOptions.push(surveyOption);
      }
    });

    setFilteredSurveyOptions(filteredOptions);
  }, [surveyOptions]);

  return (
    <Container>
      <Title>RESPONSE</Title>

      <SelectContainer isError={shouldShowErrorMessage("responseType") !== ""}>
        <SelectDropdown
          placeholder="Reponse type"
          value={formik.values.responseType}
          options={
            responseTypes?.map((responseType) => {
              return {
                label: responseType.name,
                value: responseType.type,
                key: responseType.type,
              };
            }) ?? []
          }
          onValueChange={(value) => {
            formik.setValues({});
            formik.setFieldValue("responseType", value as string);
          }}
          className={`${selectCss()} nopan nodrag`}
        />
        <ErrorMessage>{shouldShowErrorMessage("responseType")}</ErrorMessage>
      </SelectContainer>

      {formik.values.responseType === "OnceOffProtocolAction" ? (
        <SelectContainer isError={shouldShowErrorMessage("actionType") !== ""}>
          <SelectDropdown
            placeholder="Action type"
            value={formik.values.actionType}
            options={
              actionTypes?.map((actionType) => {
                return {
                  label: actionType.name,
                  value: actionType.type,
                  key: actionType.type,
                };
              }) ?? []
            }
            onValueChange={(value) => {
              formik.setFieldValue("actionType", value as string);
            }}
            className={`${selectCss()} nopan nodrag`}
          />
          <ErrorMessage>{shouldShowErrorMessage("actionType")}</ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.actionType === "Measurement" &&
      formik.values.responseType === "OnceOffProtocolAction" ? (
        <SelectContainer
          isError={shouldShowErrorMessage("measurementType") !== ""}
        >
          <Select
            placeholder="FVC"
            defaultValue={formik.values.measurementType}
            suffixIcon={<ArrowDown />}
            options={handleMeasurementTypesOptions()}
            popupClassName={popupOptionsCss()}
            onChange={(value) => formik.setFieldValue("measurementType", value)}
            className={`${selectCss()} nopan nodrag`}
          />

          <ErrorMessage>
            {shouldShowErrorMessage("measurementType")}
          </ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.actionType === "Survey" ? (
        <SelectContainer isError={shouldShowErrorMessage("surveyId") !== ""}>
          <SelectDropdown
            placeholder="Survey name"
            value={formik.values.surveyId}
            showSearch
            optionFilterProp="label"
            options={
              filteredSurveyOptions?.map((survey) => {
                return {
                  label: survey.surveyDisplayName,
                  value: survey.surveyId,
                  key: uuidv4(),
                };
              }) ?? []
            }
            onValueChange={(value) => {
              formik.setFieldValue("surveyId", value as string);
            }}
            className={`${selectCss()} nopan nodrag`}
          />
          <ErrorMessage>{shouldShowErrorMessage("surveyId")}</ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.actionType !== undefined ||
      formik.values.responseType === "FollowUpMessage" ||
      formik.values.responseType === "FollowUpQuestion" ||
      formik.values.responseType === "MessageCareTeam" ? (
        <SelectContainer
          isError={shouldShowErrorMessage("responseTime") !== ""}
        >
          <SelectDropdown
            placeholder="Response time"
            value={formik.values.responseTime}
            options={
              responseTimes?.map((responseTime) => {
                return {
                  label: responseTime.name,
                  value: responseTime.type,
                  key: responseTime.type,
                };
              }) ?? []
            }
            onValueChange={(value) => {
              formik.setFieldValue("responseTime", value as string);
            }}
            className={`${selectCss()} nopan nodrag`}
          />
          <ErrorMessage>{shouldShowErrorMessage("responseTime")}</ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.responseTime === "NextDay" ? (
        <SelectContainer isError={shouldShowErrorMessage("timeUtc") !== ""}>
          <TimePickerInput
            value={formik.values.timeUtc}
            placeholder="Time UTC"
            format="HH:mm"
            onChange={(value) => {
              formik.setFieldValue("timeUtc", value);
            }}
          />
          <ErrorMessage>{shouldShowErrorMessage("timeUtc")}</ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.responseType === "FollowUpMessage" ||
      formik.values.responseType === "StaffAlert" ? (
        <SelectContainer>
          <TextArea
            name="messageText"
            backgroudColor="white"
            placeholder={`Write ${
              formik.values.responseType === "StaffAlert" ? "alert" : "message"
            } here...`}
            maxLength={200}
            value={formik.values.messageText}
            onChange={formik.handleChange}
            className={`${textAreaCss()} nopan nodrag`}
          />
          <ErrorMessage>{shouldShowErrorMessage("messageText")}</ErrorMessage>
        </SelectContainer>
      ) : null}

      {formik.values.responseType === "FollowUpQuestion" ? (
        <>
          <SelectContainer
            style={{ marginTop: "10px" }}
            isError={shouldShowErrorMessage("questionText") !== ""}
          >
            <p>Question</p>
            <TextArea
              name="questionText"
              backgroudColor="white"
              placeholder="Type question here..."
              value={formik.values.questionText}
              onChange={formik.handleChange}
            />
            <ErrorMessage>
              {shouldShowErrorMessage("questionText")}
            </ErrorMessage>
          </SelectContainer>

          <SelectContainer style={{ marginTop: "10px" }}>
            <p>Options</p>
            <OptionsContainer>
              <TextField
                name="option1Text"
                placeholder="Option 1"
                backgroudColor="white"
                value={formik.values.option1Text}
                label=""
                onChange={formik.handleChange}
                className={`${TextFieldCss()} nopan nodrag`}
                errorMessage={shouldShowErrorMessage("option1Text")}
              />
              <TextField
                name="option2Text"
                placeholder="Option 2"
                backgroudColor="white"
                value={formik.values.option2Text}
                label=""
                onChange={formik.handleChange}
                className={`${TextFieldCss()} nopan nodrag`}
                errorMessage={shouldShowErrorMessage("option2Text")}
              />
            </OptionsContainer>
          </SelectContainer>

          <BottomContainer>
            <TrueOrFalseBox>True</TrueOrFalseBox>
            <TrueOrFalseBox isRed>False</TrueOrFalseBox>
          </BottomContainer>
          <Handle
            type="source"
            id="true"
            position={Position.Bottom}
            style={{ left: "86px" }}
          />
          <Handle
            type="source"
            id="false"
            position={Position.Bottom}
            style={{ left: "240px" }}
          />
        </>
      ) : (
        <Handle type="source" id="true" position={Position.Bottom} />
      )}
    </Container>
  );
}

export const ResponseComponentFormik = forwardRef<
  FormikProps<IWorkflowResponse>,
  IResponseComponentFormikProps
>(
  (
    {
      responseTypes,
      responseTimes,
      actionTypes,
      surveyOptions,
      measurementTypes,
      content,
    },
    ref
  ) => {
    const handleOnSubmitForm = () => {};

    const responseValidationSchema = yup.object().shape({
      responseType: yup.string().required("Response type is required."),
      responseTime: yup.string().when("responseType", {
        is: "StaffAlert",
        then: yup.string(),
        otherwise: yup.string().required("Response time is required."),
      }),
      actionType: yup.string().when("responseType", {
        is: "OnceOffProtocolAction",
        then: yup.string().required("Action type is required."),
        otherwise: yup.string(),
      }),
      measurementType: yup.string().when("actionType", {
        is: "Measurement",
        then: yup.string().required("Measurement type is required."),
        otherwise: yup.string(),
      }),
      surveyId: yup.string().when("actionType", {
        is: "Survey",
        then: yup.string().required("Survey is required."),
        otherwise: yup.string(),
      }),
      timeUtc: yup.string().when("responseTime", {
        is: "NextDay",
        then: yup.string().required("Time UTC is required."),
        otherwise: yup.string(),
      }),
      messageText: yup.string().when("responseType", {
        is: (value: string) =>
          ["FollowUpMessage", "StaffAlert"].includes(value),
        then: yup.string().required("Message is required."),
        otherwise: yup.string(),
      }),
      questionText: yup.string().when("responseType", {
        is: "FollowUpQuestion",
        then: yup.string().required("Question is required."),
        otherwise: yup.string(),
      }),
      option1Text: yup.string().when("responseType", {
        is: "FollowUpQuestion",
        then: yup.string().required("Required."),
        otherwise: yup.string(),
      }),
      option2Text: yup.string().when("responseType", {
        is: "FollowUpQuestion",
        then: yup.string().required("Required."),
        otherwise: yup.string(),
      }),
    });

    return (
      <Formik<IWorkflowResponse>
        innerRef={ref}
        initialValues={{
          responseType: content?.responseType,
          actionType: content?.actionType,
          measurementType: content?.measurementType,
          surveyId: content?.surveyId,
          responseTime: content?.responseTime,
          timeUtc: content?.timeUtc,
          messageText: content?.messageText,
          questionText: content?.questionText,
          option1Text: content?.option1Text,
          option2Text: content?.option2Text,
        }}
        onSubmit={handleOnSubmitForm}
        validationSchema={responseValidationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <ResponseComponent
              formik={formik}
              responseTypes={responseTypes}
              responseTimes={responseTimes}
              actionTypes={actionTypes}
              surveyOptions={surveyOptions}
              measurementTypes={measurementTypes}
            />
          </Form>
        )}
      </Formik>
    );
  }
);

export function ResponseNode(props: any) {
  const { data } = props;

  return (
    <>
      <Handle id="target" type="target" position={Position.Top} />
      <ResponseComponentFormik
        responseTypes={data.responseTypes}
        responseTimes={data.responseTimes}
        actionTypes={data.actionTypes}
        surveyOptions={data.surveyOptions}
        measurementTypes={data.measurementTypeOptions}
        ref={data.ref}
        content={data.content}
      />
    </>
  );
}
